/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object, array, string, number, func, bool, any,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { uid } from 'react-uid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'react-html-parser';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import TermsModal from '../Partials/BottomMenuModal';
import SocialLinks from '../Partials/SocialLinksBlock';
import extractMenuAttributes from '../../../../helpers/contentstack/extractMenuAttributes';
import { actions as tagManagerActions } from '../../../../../state/ducks/TagManager';
import { handleDoNotSellMyInformationClick } from '../../../../helpers/tracking/common/commonTrackingHelpers';
import { getFeatureFlag, getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const styles = (theme) => ({
    copyRight: {
        textAlign: 'center',
        padding: '20px 10px',
        fontSize: 14,
    },
    gridDiv: {
        maxWidth: '1440px',
        margin: 'auto',
        width: '100%',
    },
    subMenu: {
        display: 'block',
        padding: '3px 0px',
        fontSize: '14px',
        textDecoration: 'none',
        color: theme.palette.grey['50'],
        textRendering: 'optimizeLegibility',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        textAlign: theme.palette.footer?.textAlign || 'left',
    },
    menuTitle: {
        fontSize: '16px',
        padding: '7px 0px',
        display: 'block',
        textAlign: theme.palette.footer?.textAlign || 'left',
    },
    innerGrid: {
        marginBottom: '10px',
    },
    subMenuBottom: {
        padding: '7px',
        cursor: 'pointer',
        textDecoration: 'underline',
        color: theme.palette.tpColor,
    },
    firstColumn: {
        width: 'auto',
        margin: 'auto',
    },
    desktopColumn: {
        flexGrow: 0,
        maxWidth: '20%',
        flexBasis: '20%',
        padding: '40px',
    },
    socialBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginTop: '5px',
    },
    socialImage: {
        borderRadius: '50%',
        width: '100%',
    },
    socialLink: {
        width: '21%',
        marginRight: '8px',
    },
    tabletMiniContainer: {
        maxWidth: '25% !important',  // need some way to over-write css
        flexBasis: '25% !important',
        padding: '40px 20px',
    },
    footerV3Container: {
        padding: '40px 16px 40px 0px',
    },
    gridDivContainer: {
        borderTop: '1px solid rgba(47, 47, 47, 0.2)',
    },
});
const triggerClick = (self, setPopupTitle, title) => {
    setPopupTitle({ title, self });
};
const footerLink = (item, classes, styleOverrides, trackEvent) => {
    const { link: { href, title } } = item;

    const footerLinkClickHandler = (footerLinkItem) => {
        let origin = '';
        if (typeof window !== 'undefined' && window.location && window.location.origin) {
            origin = window.location.origin;
        }
        if (trackEvent) {
            trackEvent({
                eventCategory: footerLinkItem?.tracking_event_category || 'Global Footer',
                eventAction: footerLinkItem?.tracking_event_action || title || '',
                eventLabel: footerLinkItem?.tracking_event_label || title || '',
                link_url: `${origin}${href}`,
            });
        }
        if (footerLinkItem?.link?.href?.includes('submit-irm.trustarc.com')) return handleDoNotSellMyInformationClick();

        return undefined;
    };

    return (
        href?.indexOf('http://') === 0 || href?.indexOf('https://') === 0 || href?.indexOf('tel:') === 0 || href?.includes('blog') || href?.includes('articles')
            ? (
                <a
                    key={`${href}`}
                    tabIndex="0"
                    href={href}
                    className={classes.subMenu}
                    style={styleOverrides}
                    onClick={() => footerLinkClickHandler(item)}
                >
                    {ReactHtmlParser(title)}
                </a>
            )
            : (
                <Link
                    key={uid(title)}
                    to={href}
                    tabIndex="0"
                    className={classes.subMenu}
                    style={styleOverrides}
                    onClick={() => footerLinkClickHandler(item)}
                >
                    {ReactHtmlParser(title)}
                </Link>
            )
    );
};

const socialLogo = (classes, title, data) => (
    <>
        <strong className={classes.menuTitle}>
            {title}
        </strong>
        {data
         && (
             <div className={classes.socialBlock}>
                 {data.map((item) => {
                     if (item?.link?.title && item?.icon?.url && item?.link?.href) {
                         return (
                             <a tabIndex={0} key={`${item.link.title}`} className={classes.socialLink} href={item.link.href} title={item.link.title}>
                                 <img className={classes.socialImage} src={item.icon.url} alt={item.link.title} />
                             </a>
                         );
                     } return null;
                 })}
             </div>
         )}
    </>
);
const MenuColumn = ({
    columnNumber, classes, menuBlocks, attributes, widthStyle, trackEvent, adjustTabletCss, footerV3Enabled, numberOfColumns,
}) => (
    <Grid item className={`${classes.desktopColumn} ${numberOfColumns > 4 ? adjustTabletCss : ''} ${footerV3Enabled ? classes.footerV3Container : ''}`} style={widthStyle}>
        <div className={classes.innerGrid}>
            {menuBlocks
                ?.map((a) => a.menu_groups)
                ?.filter((b) => b.column === columnNumber)
                ?.map((x, index) => (
                    <div key={uid(x, index)} className={columnNumber === 1 ? classes.firstColumn : ''} style={attributes}>
                        { x.title.toLowerCase() === 'follow us' // This is still really fragile
                            ? socialLogo(classes, x.title, x.menu_item) : (
                                <>
                                    <strong className={classes.menuTitle}>
                                        {x.title}
                                    </strong>
                                    { x.menu_item.map((item) => (
                                        footerLink(item, classes, attributes, trackEvent)
                                    ))}
                                </>
                            )}
                    </div>
                ))}
        </div>
    </Grid>
);
MenuColumn.propTypes = {
    columnNumber: number.isRequired,
    classes: object.isRequired,
    menuBlocks: array.isRequired,
    attributes: object.isRequired,
    widthStyle: object.isRequired,
    trackEvent: func.isRequired,
    adjustTabletCss: string.isRequired,
    footerV3Enabled: string.isRequired,
    numberOfColumns: number.isRequired,
};

// Check this function for <a> or <Link>
const footerBottomLink = (classes, item) => {
    if (!item) {
        return null;
    }
    const href = item.link?.href || '';
    const title = item.link?.title || '';
    return (
        href.indexOf('http://') === 0 || href.indexOf('https://') === 0
            ? (
                <a  key={`${href}${title}`} href={href} target="_blank" rel="noreferrer" className={classes.subMenuBottom}>
                    {title}
                </a>
            )
            : (
                <Link
                    key={`${href}${title}`}
                    className={classes.productLink}
                    to={href}
                    tabIndex={0}
                >
                    <span className={classes.subMenuBottom}>
                        {title}
                    </span>
                </Link>
            )
    );
};
const DesktopFooterBlock = ({
    block, classes, popUpData, self, setDialog, title, openDialog, setPopupTitle, trackEvent,
}) => {
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];
    const isFood = useSelector(getPresentationFamily) === 'food';
    const adjustTabletCss  = isFood && useMediaQuery('(max-width: 1024px) and (min-width:768px)') ? classes.tabletMiniContainer : '';
    const footerV3Enabled = useSelector(getFeatureFlag('is-footer-v3-enabled')) && isFood;
    switch (blockKey) {
        case 'menu_group_links': {
            const menu = blockObj.menu_group_link_ref[0];
            const attributes = extractMenuAttributes(menu);

            // The background color on footer item is not being used anywhere and causing issues with
            // changing the color at different places to change background color of footer
            // removing thisbackground color from attributes and added a property `desktopBgFooter` in brand configuration CMS to control
            // background color of footer.
            if (attributes.backgroundColor) {
                delete attributes.backgroundColor;
            }

            let numberOfColumns = 1;
            // eslint-disable-next-line no-unused-expressions
            menu?.menu_blocks
                ?.map((a) => a.menu_groups)
                ?.forEach((b) => { numberOfColumns = Math.max(b.column, numberOfColumns); });
            const widthPercent = `${100.0 / numberOfColumns}%`;
            const widthStyle = { maxWidth: widthPercent, flexBasis: widthPercent };
            const columns = [];
            for (let n = 1; n <= numberOfColumns; n += 1) {
                columns.push(<MenuColumn trackEvent={trackEvent} columnNumber={n} key={n} classes={classes} menuBlocks={menu?.menu_blocks} attributes={attributes} widthStyle={widthStyle} adjustTabletCss={adjustTabletCss}  footerV3Enabled={footerV3Enabled} numberOfColumns={numberOfColumns} />);
            }

            return (
                <div className={`${classes.gridDiv} ${footerV3Enabled ? classes.gridDivContainer : ''}`}>
                    <Grid container className={classes.parentGrid}>
                        {columns}
                    </Grid>
                </div>
            );
        }
        case 'copyright':
            return (
                <>
                    <div className={classes.copyRight} style={{ color: blockObj.color }}>
                        {blockObj?.copy}
                        {popUpData?.map((item, i) => (item.popup ? (
                            <span
                                key={`${item.link.title}`}
                                onClick={() => {
                                    triggerClick(self, setPopupTitle, item.popup_title);
                                }}
                                role="presentation"
                                className={classes.subMenuBottom}
                                title={item.link.title}
                            >
                                {item.link.title}
                            </span>
                        ) : (
                            <React.Fragment key={`${item.link.title}`}>
                                {i > 0  && <span>-</span>}
                                {  footerBottomLink(classes, item)}

                            </React.Fragment>
                        )

                        ),

                        )}
                    </div>
                    <TermsModal self={self} setDialog={setDialog} title={title} show={openDialog} data={popUpData} />
                </>

            );
        case 'social_links':
            return <SocialLinks data={blockObj} />;
        default:
            // console.warn('unhandled footer block', blockKey); // TODO: Convert to mbpLogger?
            return null;    // TODO: log warning for unhandled block
    }
};
DesktopFooterBlock.propTypes = {
    block: object.isRequired,
    classes: object.isRequired,
    popUpData: array,
    title: string,
    openDialog: bool,
    setDialog: func,
    setPopupTitle: func,
    self: any,
    trackEvent: func.isRequired,
};

DesktopFooterBlock.defaultProps = {
    popUpData: [],
    title: '',
    openDialog: false,
    setDialog: () => {},
    setPopupTitle: () => {},
    self: null,
};

const mapDispatchToProps = {
    trackEvent: tagManagerActions.trackEvent,
};

const enhance = compose(
    withStyles(styles, { withTheme: true }),
    connect(null, mapDispatchToProps),
);

export default enhance(DesktopFooterBlock);
